import React, { useEffect } from "react";
import styles from "./Slide1.module.css";
import homeVideo from "../../asssets/videos/home.mp4";
import patlangac from "../../asssets/images/badge.png"

function Slide1() {
  useEffect(() => {}, []);

  return (
    <div className={styles.slide1_container}>
       <img src={patlangac} alt="İstanbul" className={styles.patlangac} />
      {/* <video className={styles.video_container} src={homeVideo} loop autoplay muted loading="lazy"></video> */}
      <video className={styles.video_container} autoPlay loop="loop" muted playsInline preload="auto">
        <source src={homeVideo} type="video/mp4"></source>
      </video>
      <p>
          NineSixteen Academy, <nobr>9-16</nobr>’ya ve sektöre yeni yetenekler kazandırmak amacıyla <nobr>9-16</nobr> ve İstanbul Bilgi Üniversitesi İletişim Fakültesi tarafından sunulan bir eğitim ve staj programıdır. İkincisini düzenlediğimiz Academy 4-15 Eylül 2023 tarihlerinde gerçekleşecek.<br/>
          Motion Design, Art Direction ya da Copywriting alanında kendini geliştirmek istiyorsan, üniversite 3. veya 4. sınıf öğrencisi ya da yeni mezunsan NineSixteen Academy’e kesin başvurmalısın!
      </p>
    </div>
  );
}

export default Slide1;
