import React from "react";
import videoSkate from "../../asssets/videos/academy-Skateboard.mp4";
import styles from "./CommonSl.module.css";
import {Link} from "react-router-dom";

function Slide4() {
  return (
    <div className={styles.slide_container}>
      <div className={styles.caption} style={{margin: "0 0 -4rem"}}>
          <h2>Nasıl Katılabilirim?</h2>
          <p>
              Başvurunu yap.<br/>
              Online videoları izle.<br/>
              Başvuran adayların e-postalarına gelecek olan brief’e uygun en kreatif işini üret ve bize gönder.<br/>
              NineSixteen Academy eğitim programına seçilecek yeteneklerden biri olma şansını yakala!<br/>
              <span style={{margin: "2rem 0 3rem", display: "block"}}><Link className={styles.submitButton} to={"/register"}>Hadi <strong>başvur</strong></Link></span>
          </p>
      </div>
      <div>
        <video className={styles.video_container} autoPlay loop="loop" muted playsInline preload="auto">
          <source src={videoSkate} type="video/mp4"></source>
        </video>
      </div>
    </div>
  );
}

export default Slide4;
