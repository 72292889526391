import React from "react";
import videoHeart from "../../asssets/videos/academy-heart.mp4";
import heart from "../../asssets/images/kalp.png";
import logoBlack from "../../asssets/images/logo-white.png";
import styles from "./CommonSl.module.css";
import {Link} from "react-router-dom";

function Slide2() {
  return (
    <div className={styles.slide_container}>
      <div className={styles.caption}>
        <h2>
          <img src={logoBlack} alt="logo-black" style={{ height: "4rem", marginRight: "1rem" }} />
          <img src={heart} alt="heart" /> Yeni Yetenekler
        </h2>
        <div>
            <p>
                <span style={{margin: "3rem 0 2rem", display: "block"}}><strong>Bundan sonrası çok keyifli olacak…</strong></span>
                Eğitimler <strong>iki</strong> hafta sürecek ve yüz yüze gerçekleşecek. 2 hafta Motion Designer’lar, <strong>eş zamanlı</strong> olarak 1 hafta Art Director’lar ve 1 hafta Copywriter’lar işin inceliklerini 9-16’nın yaratıcı ekibinden öğrenecek. Böylece kreatif ekibin bir parçası olarak çalışmayı deneyimleyeceksin.
                <span style={{margin: "2rem 0", display: "block"}}><strong>Üstelik burada bitmiyor…</strong></span>
                Eğitime katılanlar arasından seçilen yeni yeteneklerden birisi olabilir ve kendi alanında 9-16’da staj yapma fırsatını elde edebilirsin.
                <span style={{margin: "2rem 0", display: "block"}}><strong>Dahası da var…</strong></span>
                Üç aylık stajın ardından sen de aramıza katılabilir ve kariyerine bir NineSixteener olarak başlayabilirsin!
                <span style={{margin: "2rem 0 3rem", display: "block"}}><Link className={styles.submitButton} to={"/register"}>Hemen <strong>başvur!</strong></Link></span>
            </p>
        </div>
      </div>
      <div>
        {/* <video className={styles.video_container} src={videoHeart} loop autoPlay muted loading="lazy"></video> */}
        <video className={styles.video_container} autoPlay loop="loop" muted playsInline preload="auto">
          <source src={videoHeart} type="video/mp4"></source>
        </video>
      </div>
    </div>
  );
}

export default Slide2;
