import React from "react";
//import React, {useState} from "react";
import styles from "./Register.module.css";
// import axios from "axios";
import Navbar from "../subs/Navbar";
import Footer from "../subs/Footer";
// import videoCrown from "../../asssets/videos/academy-crown.mp4";
// import { BsLink45Deg } from "react-icons/bs";
//import caseFolder from "../../asssets/files/AcademyCase2022.zip";
//import { FaFileDownload } from "react-icons/fa";
import sadFace from "../../asssets/images/sadFace.png";

function Register() {
/*   const [user, setUser] = useState({ fullName: "", school: "", department: "", class: "", email: "", age: "", phone: "", city: "", question1: "Arkadaşımdan / Tanıdığımdan", question2: "Video Design", experience: "", notes: "" });
   const question1List = [
       "Arkadaşımdan / Tanıdığımdan",
       "Sosyal Medyadan",
       "Okulumun Duyurusundan",
       "İnternette Araştırırken"
   ]
   const question2List = [
        "Video Design",
        "Copywriting",
        "Art Direction"
   ]
   const [registerMessage, setRegisterMessage] = useState("");


   const onSubmit = (e) => {
     e.preventDefault();
     axios
       .post("https://sheet.best/api/sheets/bce396c7-ab8d-407a-ab03-b399d4acf846", {
         Name: user.fullName,
         University: user.school,
         Department: user.department,
         Class: user.class,
         Email: user.email,
         City: user.city,
         Age: user.age,
         Question1: user.question1,
         Question2: user.question2,
         Experience: user.experience,
         Notes: user.notes,
         Phone: user.phone
       })
       .then(function (response) {
         console.log(response);

         setUser({ fullName: "", school: "", department: "", class: "", email: "", age: "", phone: "", city: "", question1: "Arkadaşımdan / Tanıdığımdan", question2: "Video Design", experience: "", notes: "" });
         setRegisterMessage(`Tebrikler ${user.fullName}! Kaydınız başarıyla alınmıştır.`);
       })
       .catch(function (error) {
         console.log(error);
         setRegisterMessage(`Üzgünüz! Bir hata oluştu :( Lütfen daha sonra tekrar deneyin.`);
       });
     setTimeout(() => {
       setRegisterMessage("");
     }, 5000);
   };*/
  return (
    <div className={styles.main}>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.form_container}>
          <h2>Maalesef bu dönem için başvuru sürecini tamamladık.</h2>
          <p>
            Ancak buraları ara ara mutlaka yokla. Yeni eğitim videolarıyla kendini geliştirebilir, bir sonraki dönem için vaktinde kaydolabilirsin.{" "}
            <br></br>
            <br></br>
            Görüşmek üzere.
          </p>
          {/*<form onSubmit={onSubmit}>

              <div className="row g-2">
                  <div className="col-12 col-md-5">
                      <div className={styles.name}>
                          <input

                              type="text"
                              name="name"
                              placeholder="Ad Soyad"
                              value={user.fullName}
                              onChange={(e) => setUser({ ...user, fullName: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
                  <div className="col-12 col-md-2">
                      <div className={styles.email}>
                          <input

                              type="text"
                              name="age"
                              placeholder="Yaş"
                              value={user.age}
                              onChange={(e) => setUser({ ...user, age: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12 col-md-5">
                      <div className={styles.school}>
                          <input

                              type="text"
                              name="school"
                              placeholder="Üniversite"
                              value={user.school}
                              onChange={(e) => setUser({ ...user, school: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
                  <div className="col-12 col-md-5">
                      <div className={styles.school}>
                          <input
                              type="text"
                              name="department"
                              placeholder="Bölüm"
                              value={user.department}
                              onChange={(e) => setUser({ ...user, department: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
                  <div className="col-12 col-md-2">
                      <div className={styles.school}>
                          <input
                              type="text"
                              name="class"
                              placeholder="Sınıf"
                              value={user.class}
                              onChange={(e) => setUser({ ...user, class: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12 col-md-5">
                      <div className={styles.email}>
                          <input

                              type="email"
                              name="email"
                              placeholder="E-Mail"
                              value={user.email}
                              onChange={(e) => setUser({ ...user, email: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
                  <div className="col-12 col-md-5">
                      <div className={styles.email}>
                          <input
                              type="phone"
                              name="phone"
                              placeholder="Telefon"
                              value={user.phone}
                              onChange={(e) => setUser({ ...user, phone: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
                  <div className="col-12 col-md-5">
                      <div className={styles.email}>
                          <input
                              type="text"
                              name="city"
                              placeholder="Şehir"
                              value={user.city}
                              onChange={(e) => setUser({ ...user, city: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12">
                      <label>NineSixteen Academy’i nereden duydun?</label>
                  </div>
                  <div className="col-12 col-md-7">
                      <div className={styles.email}>
                          <select
                              className="form-select"
                              onChange={(e) => setUser({...user, question1: e.target.value})}
                              required
                              name="question1"
                              value={user.question1}
                              placeholder={"NineSixteen Academy’i nereden duydun?"}>
                              {question1List.map((q1, index) => {
                                  return (
                                      <option key={index} value={q1}>{q1}</option>
                                  );
                              })}
                          </select>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12">
                      <label>NineSixteen Academy’e hangi alanda dahil olmak istersin?</label>
                  </div>
                  <div className="col-12 col-md-7">

                      <div className={styles.email}>
                          <select
                              className="form-select"
                              onChange={(e) => setUser({...user, question2: e.target.value})}
                              required
                              name="question2"
                              value={user.question2}
                              placeholder={"NineSixteen Academy’e hangi alanda dahil olmak istersin?"}>
                              {question2List.map((q1, index) => {
                                  return (
                                      <option key={index} value={q1}>{q1}</option>
                                  );
                              })}
                          </select>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12">
                      <div className={styles.email}>
                          <input
                              type="text"
                              name="experience"
                              placeholder="Bu alanda bir deneyimin var mı?"
                              value={user.experience}
                              onChange={(e) => setUser({ ...user, experience: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12">
                      <div className={styles.email}>
                          <input
                              type="text"
                              name="notes"
                              placeholder="Eklemek istediğin bir şey var mı?"
                              value={user.notes}
                              onChange={(e) => setUser({ ...user, notes: e.target.value })}
                              required
                          ></input>
                      </div>
                  </div>
              </div>
              <div className="row g-2 mt-1">
                  <div className="col-12 col-md-3">
                      <div className={styles.submit}>
                          <input type="submit" value="Kaydol" />
                      </div>
                  </div>
                  <div className="col-12">
                      <p
                          style={{
                              display: registerMessage.length ? "block" : "none",
                              height: "2rem",
                              marginTop: "1rem",
                          }}
                          className={styles.confirmation}
                      >
                          {registerMessage}
                      </p>
                  </div>
                  <div className="col-12">
                    <button className={styles.downloadButton}>
                      <FaFileDownload style={{ color: "white", width: "2rem", height: "2rem" }} />
                      <a href={caseFolder} download="Academy Case">
                        Download the Case
                      </a>
                    </button>
                  </div>
              </div>
        </form>*/}


{/*          <a className={styles.kvk_link} href="https://9-16.com/kvkk" target="_blank" rel="noreferrer">
          <BsLink45Deg style={{ width: "1rem", height: "1rem" }} /> Kişisel Verilerin Korunması ve İşlenmesine İlişkin Aydınlatma Metnine buradan
          ulaşabilirsiniz
        </a>*/}
        </div>
        <div className={styles.image_container}>
           <img src={sadFace} alt="sad_face" />
           {/* <video className={styles.video_container} autoPlay loop="loop" muted playsInline preload="auto">
                <source src={videoCrown} type="video/mp4"></source>
            </video>*/}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
