import React from "react";
import styles from "./Courses.module.css";
import { Carousel } from "3d-react-carousal";
import Navbar from "../subs/Navbar";
import Footer from "../subs/Footer";
import videoToVideo from "../../asssets/videos/courses/videoToVideo.mp4";
import eyeTracking from "../../asssets/videos/courses/eyeTracking.mp4";
import repurpose from "../../asssets/videos/courses/repurpose.mp4";
import soundEdit from "../../asssets/videos/courses/soundEdit.mp4";
import safeZone from "../../asssets/videos/courses/safeZone.mp4";
import etkiliFont from "../../asssets/videos/courses/tasarimdaEtkiliFont.mp4";

function Courses() {
  let slides = [
    <div className={styles.video_container}>
      <h3>Video to Video</h3>
      <video className="video" controls preload="auto">
        <source src={videoToVideo} type="video/mp4"></source>
      </video>
    </div>,
    <div className={styles.video_container}>
      <h3>Eye Tracking</h3>
      <video className="video" controls preload="auto">
        <source src={eyeTracking} type="video/mp4"></source>
      </video>
    </div>,
    <div className={styles.video_container}>
      <h3>Repurpose Video</h3>
      <video className="video" controls preload="auto">
        <source src={repurpose} type="video/mp4"></source>
      </video>
    </div>,
    <div className={styles.video_container}>
      <h3>Sound Editing and Sound Design</h3>
      <video className="video" controls preload="auto">
        <source src={soundEdit} type="video/mp4"></source>
      </video>
    </div>,
    <div className={styles.video_container}>
      <h3>Safe Zone</h3>
      <video className="video" controls preload="auto">
        <source src={safeZone} type="video/mp4"></source>
      </video>
    </div>,
    <div className={styles.video_container}>
      <h3>Effective font in design</h3>
      <video className="video" controls preload="auto">
        <source src={etkiliFont} type="video/mp4"></source>
      </video>
    </div>,
    // <div className={styles.video_container}>
    //   <video controls preload="auto">
    //     <source src={ComingSoonVideo} type="video/mp4"></source>
    //   </video>
    // </div>,
  ];
  const handleSlideChange = (ind) => {
    const slides = document.getElementsByClassName("video");
    for (let index = 0; index < slides.length; index++) {
      slides[index].pause();
    }
  };

  return (
    <div className={styles.main}>
      <Navbar />
      <div className={styles.videos_container}>
        <Carousel slides={slides} autoplay={false} onSlideChange={handleSlideChange} />
      </div>
      <Footer />
    </div>
  );
}

export default Courses;
