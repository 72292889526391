import React from "react";
import videoQMark from "../../asssets/videos/academy-questionmark.mp4";
import styles from "./CommonSl.module.css";

function Slide5() {
  return (
    <div className={styles.slide_container}>
      <div className={styles.caption}>
        <h2>Sorulması Muhtemel Sorular</h2>
        <p>
            <ul>
                <li>Eğitimler hafta içi her gün 10.00-17.00 arasında gerçekleşecek.</li>
                <li>Öğle yemeği programa dahil ve 9-16 tarafından karşılanıyor.</li>
                <li>NineSixteen Academy tamamen ücretsiz.</li>
                <li>Eğitimler İstanbul Bilgi Üniversitesi İletişim Fakültesi Santral Kampüsü İletişim Fakültesi’nde yüz yüze gerçekleşiyor.</li>
                <li>Herhangi bir sorun olursa <a href="mailto:academy@9-16.com"><b>academy@9-16.com</b></a> adresinden bize ulaşabilirsin.</li>
            </ul>
        </p>
      </div>
      <div>
        <video className={styles.video_container} autoPlay loop="loop" muted playsInline preload="auto">
          <source src={videoQMark} type="video/mp4"></source>
        </video>
      </div>
    </div>
  );
}

export default Slide5;
