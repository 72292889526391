import React from "react";
import styles from "./Footer.module.css";
import instaLogo from "../../asssets/images/instagram.png";
//import bilgi916 from "../../asssets/images/916Bilgi.png";
//import bilgiTalent from "../../asssets/images/bilgiTalent.png";
import iletisimFaculty from "../../asssets/images/logo_collaboration.png";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.logoContainer}>
        <a href="https://www.instagram.com/9_16/" target="_blank" rel="noopener noreferrer">
          <img src={instaLogo} alt="insta-logo" className={styles.instaLogo} />
        </a>
      </div>
      <div className={styles.logoContainer}>
          <img src={iletisimFaculty} alt="bilgi-talent-logo" className={styles.bilgiTalentLogo} />
      </div>
      <div className={styles.logoContainer}>

      </div>
    </div>
  );
}

export default Footer;
